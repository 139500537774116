@import "Colors.scss";
@import "Fonts.scss";

$background-image-url: "../static/assets/MapBackgroundBlue1-1.png";
// $background-image-url: "../static/assets/MapBackgroundBlueDark1-1.png";
$background-image-url-mobile: "../static/assets/MapBackgroundBlue1-2.png";

@keyframes bounce {
  0%,
  100% {
    transform: scale(1);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: scale(1.2);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

@keyframes bounce-down {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px); /* Adjust the value for more or less bounce */
  }
}

.section1-wrapper {
  height: calc(var(--vh, 1vh) * 100 - 100px);
  // height: 100vh;
  background: linear-gradient(454deg, #e5e8f0 15%, #ffffff 70%);
  display: flex;
  flex-direction: row;

  .section1-sub1-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    margin: 0px 0px 0px 5%;
    justify-content: center; // Center the content vertically

    .section1-sub1-content {
      width: 75%;

      #section1-sub1-title {
        color: $grid-darkBlue;
        font-family: "Metropolis-Bold";
        font-size: 60px;
        margin-bottom: 12px;
        line-height: 1;
      }

      #section1-sub1-subtitle {
        margin-bottom: 24px;
        color: #3f517c;
        font-family: "Metropolis-SemiBold";
      }

      #section1-sub1-description {
        color: $grid-darkBlue;
        font-family: "Metropolis";
        font-size: 24px;
      }
    }

    .download-app-button {
      padding: 0px;
      background: none;
      border: none;
      cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
      width: 200px;
      margin-top: 20px;

      #AppStoreButton {
        width: 200px;
      }
    }
  }

  .section1-sub2-wrapper {
    display: flex;
    justify-content: center; // Horizontally center the image
    align-items: center; // Vertically center the image
    width: 50%;
    height: 100%;
    margin: 0;

    .section1-image {
      max-width: 80%;
      max-height: calc(var(--vh, 1vh) * 60);
      min-height: calc(var(--vh, 1vh) * 40);
      aspect-ratio: 1 / 1;
      background-image: url($background-image-url); // Set the background image
      background-size: cover;
      background-repeat: no-repeat; // Prevent repeating the image
      background-position: center center; // Center the image
      border-radius: 20px;
      box-shadow: 0 10px 80px rgba($grid-darkBlue, 1);
      transition: transform 0.3s ease-in-out;

      /* Hover effect to scale the image */
      &:hover {
        transform: scale(1.05);
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        padding: 50px;
      }
    }
  }
}

//  Style for mobile
// Style for mobile
@media (max-width: 900px) {
  .section1-wrapper {
    // height: calc(var(--vh, 1vh) * 100 - 70px);
    height: auto;
    background: linear-gradient(454deg, #e5e8f0 15%, #ffffff 70%);
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    .section1-sub1-wrapper {
      display: flex;
      flex-direction: column;
      // height: calc(var(--vh, 1vh) * 50 - 35px);
      width: 100%;
      margin: 0;
      padding: 0;
      flex: 1;
      justify-content: center;
      align-content: center;
      align-items: center;
      margin-top: 5vh;
      margin-bottom: 5vh;

      .section1-sub1-content {
        // width: 90%;
        max-width: 90%;
        // width: 100%;
        // height: 70%;
        // margin: auto;

        #section1-sub1-title {
          font-size: 2em;
          margin-bottom: 6px;
          // white-space: nowrap;
        }

      #section1-sub1-subtitle {
          font-size: 16px;
          margin-bottom: 12px;
          color: #3f517c;
          font-family: "Metropolis-SemiBold";
        }

        #section1-sub1-description {
          color: $grid-darkBlue;
          font-family: "Metropolis";
          font-size: 14px;
          margin-bottom: 0px;
        }
      }

      .download-app-button {
        width: 100%;
        height: 30%;
        // margin-top: 20px;

        #AppStoreButton {
          // margin-top: 20px;
          width: 35vw;
          max-width: 150px;
        }
      }
    }

    .section1-sub2-wrapper {
      display: flex;
      width: 100%;
      // max-height: calc(var(--vh, 1vh) * 50 - 35px);
      flex: 1;
      margin-top: 5vh;
      margin-bottom: 5vh;
      .section1-image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 90%;

        .section1-image {
          min-height: 0px;
          max-height: calc(var(--vh, 1vh) * 50);
          aspect-ratio: 1 / 1;
          background-image: url($background-image-url-mobile);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          border-radius: 10px;
          box-shadow: 0 5px 40px rgba($grid-darkBlue, 1);

          img {
            padding: 25px;
          }
        }
      }
    }
  }
}

@media (max-width: 390px) {
  .section1-wrapper {
    .section1-sub1-wrapper {
      width: 80%;
      .section1-sub1-content {
        // width: 100%;
        // height: 50%;
        // overflow: hidden;

        #section1-sub1-title {
          font-size: 24px;
        }

        #section1-sub1-subtitle {
          font-size: 14px;
        }

        #section1-sub1-description {
          font-size: 12px;
          margin-bottom: 10px;
        }
      }

      // .download-app-button {
      //   width: 100%;
      //   margin-top: 0px;

      //   #AppStoreButton {
      //     width: 125px;
      //   }
      // }
    }
  }
}
