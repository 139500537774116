@import "Colors.scss";
@import "Fonts.scss";

// $background-image-url: '../static/assets/MapBackgroundBlue1-1.png'; // Define the path to your image

.support-page-wrapper {
    padding-top: 100px;
    height: calc(100vh);
    background-color: $grid-darkBlue;
    
    width: 40%;
    margin: 0 auto 0 auto;
    

    .support-page {
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto; /* Enables vertical scrolling */
        
        .support-page-text {
            color: white;
            font-family: "Metropolis";
            margin-bottom: 0px;
            
            .support-page-text-header {
                font-weight: bold; // For Medium, for example
                font-style: bold;
                font-size: 80px;
            }

            .support-page-text-body {
                font-weight: bold; // For Medium, for example
                font-style: bold;
                font-size: 18px;
                background-color: rgba(67, 61, 110, 0.5);
                border-radius: .4rem;
                padding:0.5rem 0.5rem 0.5rem 1rem;

                .nav-link,
                a {
                    color: $grid-pink;
                }

                p {
                    padding-top: 14px;
                    font-weight: 600; // For Medium, for example
                    font-style: normal;
                }
            }
            // padding-bottom: 24px;
        }

        .support-page-form-wrapper {
            width: 100%;
            margin: 5vh, auto;
            padding: 7%;
            padding-bottom: 4%;

            .support-page-form {
                display: flex;
                align-items: center;
                flex-direction: column;
                gap: .5rem;
                .support-page-form-group {
                    width: 90%;
                    .signup-form-input {
                        width: 100%;
                        margin-bottom: 1rem;
                        border-radius: 1rem;
                        font-family: "Metropolis";
                        font-weight: 400; // For Medium, for example
                        font-style: normal;
                        font-size: 1.25rem;
                        padding: 0.5rem;
                    }

                    .signup-form-input::placeholder {
                        color: $grid-darkBlue;
                    }
                }
            }

            .signup-form-button {
                border-radius: 1rem; /* Corner radius */
                border: 0.15rem solid $grid-pink;
                padding: 0.5rem 1rem;
                background-color: $grid-darkBlue;
                color: $grid-pink;
                cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
                font-family: "Metropolis";
                font-weight: bold; // For Medium, for example
                font-style: bold;
                font-size: 1.4rem;
                width: 50%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 20px;
                white-space: nowrap;
            }

            .signup-form-status {
                color: $grid-pink;
                margin-top: 1rem;
                width: 90%;
                text-align: center;
                font-size: 1rem;
            }

            .clicked {
                background-color: $grid-pink;
                color: $grid-darkBlue;
            }
        }
    }
}

@media (max-width: 900px) {
    .support-page-wrapper {
        padding-top: 70px;
        height: calc(100vh);
        width: 80%;
        overflow-y: auto; /* Enables vertical scrolling */
        justify-content: space-between;

        .support-page {
            .support-page-text {
                margin-bottom: 1vh;
                .support-page-text-header {
                    font-size: 44px;
                    margin-bottom: 1vh;
                }

                .support-page-text-body {
                    font-size: 16px;
                    p {
                        margin-bottom: 0px;
                        padding-top: 5px;
                    }
                }
                padding-bottom: 0px;
            }

            .support-page-form-wrapper {
                width: 100%;
                margin-top: 1rem;
                .support-page-form {
                    .support-page-form-group {
                        width: 100%;
                        .signup-form-input {
                            width: 100%;
                            font-family: "Metropolis";
                            font-weight: 400; // For Medium, for example
                            font-style: normal;
                            font-size: 16px;
                            padding: 8px 0px 8px 16px;
                        }

                        .signup-form-input::placeholder {
                            color: $grid-darkBlue;
                        }
                    }
                }

                .signup-form-button {
                    border-radius: 15px; /* Corner radius */
                    border: 2px solid $grid-pink;
                    padding: 4px 16px;
                    background-color: $grid-darkBlue;
                    color: $grid-pink;
                    cursor: pointer; /* Changes the cursor to a pointer to indicate it's clickable */
                    font-family: "Metropolis";
                    font-weight: bold; // For Medium, for example
                    font-style: bold;
                    font-size: 22px;
                    width: 80%;
                    margin-top: 0px;
                }

                .signup-form-status {
                    color: $grid-pink;
                    margin-top: 20px;
                    width: 90%;
                    text-align: center;
                }

                .clicked {
                    background-color: $grid-pink;
                    color: $grid-darkBlue;
                }
            }
        }
    }
}
