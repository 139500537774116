@import "Colors.scss";
@import "Fonts.scss";

$background-image-url: "../static/assets/MapBackgroundBlue1-1.png"; // Define the path to your image

.section2-wrapper {
  height: 300vh;
  background: linear-gradient(454deg, #e5e8f0 15%, #ffffff 70%);

  display: flex;
  flex-direction: column;
  align-items: center;

  .section2-title {
    height: 100px;
    color: white;
    font-family: "Metropolis";
    font-weight: 800; // For Medium, for example
    font-style: bold;
    font-size: 44px;
    background-color: #3f517c;
    width: 100%;
    line-height: 1;
    text-align: center;
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center;
  }

  .section2-body {
    display: flex;
    flex-direction: row;
    width: 80%;

    margin: auto 0 auto 0;
    .section2-main {
      width: 100%;
      // height: 80%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;

      .section2-row {
        width: 100%;
        height: 70vh;
        display: flex;
        flex-direction: row;

        &.row-reverse {
          flex-direction: row-reverse; // Reverse the row when this class is applied
        }

        .section2-info-wrapper {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          .section2-info {
            width: 75%;
            .section2-info-title {
              color: $grid-pink;
              color: #3f517c;
              font-family: "Metropolis-Bold";
              font-size: 60px;
            }
            .section2-info-body {
              color: $grid-darkBlue;
              font-family: "Metropolis";
              font-size: 24px;
            }
          }
        }
        .section2-row-image-wrapper {
          width: 50%;
          display: flex;
          justify-content: center;
          .section2-row-image {
            height: auto;
            object-fit: contain;
            filter: drop-shadow(10px 10px 10px rgba($grid-darkBlue, 1));
          }
        }
      }
  }
  }
}

@media (max-width: 900px) {
  .section2-wrapper {
    // height: calc(360vh + 70px + 70px + 30px * 4);
    height: auto;
    justify-content: center;
    align-items: center;

    .section2-title {
      font-size: 24px;
      font-family: "Metropolis-SemiBold";
      height: 70px;
      width: 100%;
      margin-bottom: 70px;
    }

    .section2-body {
      display: flex;
      flex-direction: column;
      padding: 0;

      .section2-main {
        align-items: center;
        height: 100%;

        .section2-row {
          flex-direction: column;
          align-items: center;
          height: auto;
          margin-bottom: 30px; /* Added margin to create consistent spacing between rows */

          &.row-reverse {
            flex-direction: column;
          }

          .section2-info-wrapper {
            width: 100%;
            height: auto;
            text-align: start;
            overflow-y: hidden;
            margin-bottom: 20px; /* Consistent margin between info and image */

            .section2-info {
              width: 100%;
              height: 100%;
              padding-bottom: 10px; /* Spacing between info and row image */

              .section2-info-title {
                font-size: 22px;
                margin: 0px;
              }

              .section2-info-body {
                font-size: 16px;
                margin: 0px;
              }
            }
          }

          .section2-row-image-wrapper {
            width: 100%;
            max-height: 70vh;
            margin-top: 20px; /* Consistent margin between image and the next row */
            margin-bottom: 20px; /* Consistent margin between this row image and the next row info */

            .section2-row-image {
              max-height: 70vh;
              filter: drop-shadow(5px 5px 5px rgba($grid-darkBlue, 1));
            }
          }
        }
      }

      .section2-photo {
        height: 50%;
        width: 80%;
        margin: auto;

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
