@import "Colors.scss";
@import "Fonts.scss";

.landing-page-wrapper {
    display: flex;
    flex-direction: column;
    // height: calc(420vh - 200px);
    // height: calc(420vh);
    padding-top: 100px;
    background-color: #FFFFFF;
}

@media (max-width: 900px) {
    
    .landing-page-wrapper {
        padding-top: 70px;
        // height: calc(480vh - 210px);
    }

}