@import "Colors.scss";
@import "Fonts.scss";

.header-container {
  padding: 0px 15px;
  width: 100%;
  height: 100px;
  background-color: $grid-darkBlue;
  // background-color: rgba($grid-darkBlue, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // border-bottom: 3px solid $grid-pink;
  position: fixed;
  box-shadow: 0 0px 10px $grid-darkBlue;
  z-index: 10;

  .menu-icon-wrapper {
    display: none;
  }

  .logo-wrapper {
    .logo-image {
      height: 40px;
    }
  }

  .nav-wrapper {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    justify-content: center;
    flex-grow: 1;

    .nav-link {
      background-color: none;
      color: $grid-pink;
      font-family: "Metropolis-SemiBold";
      font-weight: 500; // For Medium, for example
      font-style: black;
      font-size: 22px;
      margin-left: 30px;
    }
  }

  .social-link-wrapper {
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    .social-link img {
      height: 30px;
      width: auto;
      margin-right: 20px;
    }
  }

  .dropdown-menu {
    display: none;
  }
}

//  Style for mobile
@media (max-width: 768px) {
  .header-container {
    .menu-icon-wrapper {
      display: inline-block;
      .menu-icon {
        max-height: 24px;
      }
      margin-right: 20px;
    }

    .logo-wrapper {
      .logo-image {
        height: 28px;
      }
    }

    .nav-wrapper {
      display: none;
    }

    .dropdown-menu {
      padding: 0px;
      display: flex;
      flex-direction: row;
      position: absolute;
      top: 70px; /* Directly below the header */
      left: 0;
      width: 100%; /* Or as per your design */
      z-index: 10; /* To ensure it's above the content below */
      background-color: $grid-darkBlue; /* Or any color that suits your design */
      /* Additional styling */
      border-radius: 0;
      border-top: 1px solid $grid-pink;
      border-left: 1px solid $grid-darkBlue;
      border-right: 1px solid $grid-darkBlue;
      border-bottom: 2px solid $grid-pink;
      box-shadow: 0 0px 10px $grid-darkBlue;
      
      .dropdown-link {
        background-color: none;
        color: $grid-pink;
        font-family: "Metropolis-SemiBold";
        font-size: 20px;
        margin-left: auto;
        margin-right: auto;
        margin: 5px auto 5px auto;
      }

      .divider {
        border-right: 3px solid $grid-pink;
      }
    }
  }
}

@media (max-width: 900px) {
  .header-container {
    height: 70px;
  }
}

@media (max-width: 300px) {
  .header-container {
    .menu-icon-wrapper {
      display: inline-block;
      .menu-icon {
        height: 18px;
      }
      margin-right: 5%;
    }

    .logo-wrapper {
      .logo-image {
        height: 20px;
      }
    }

    .nav-wrapper {
      display: none;
    }

    .social-link-wrapper {
      width: 40%;
      .social-link img {
        height: 20px;
        margin-right: 10%;
      }
    }
  }
}
