@font-face {
    font-family: 'Metropolis-Bold';
    src: url('../static/fonts/Metropolis-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis-SemiBold';
    src: url('../static/fonts/Metropolis-SemiBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../static/fonts/Metropolis-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../static/fonts/Metropolis-ExtraBold.ttf') format('truetype');
    font-weight: 800; // ExtraBold
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../static/fonts/Metropolis-Light.ttf') format('truetype');
    font-weight: 300; // Light
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../static/fonts/Metropolis-Medium.ttf') format('truetype');
    font-weight: 500; // Medium
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../static/fonts/Metropolis-MediumItalic.ttf') format('truetype');
    font-weight: 500; // Medium
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../static/fonts/Metropolis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../static/fonts/Metropolis-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../static/fonts/Metropolis-SemiBold.ttf') format('truetype');
    font-weight: 600; // SemiBold
    font-style: normal;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../static/fonts/Metropolis-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600; // SemiBold
    font-style: italic;
}

@font-face {
    font-family: 'Metropolis';
    src: url('../static/fonts/Metropolis-Thin.ttf') format('truetype');
    font-weight: 100; // Thin
    font-style: normal;
}