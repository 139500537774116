@import "Colors.scss";
@import "Fonts.scss";

// .title {
//     font-family: 'Metropolis', sans-serif;
//     color: white;
//     max-width: 800px;
//     margin: 0 auto;
//     padding: 40px;
// }
.policy-wrapper {
    padding-top: 100px;

    .policy-content {
        font-family: "Metropolis", sans-serif;
        color: white;
        max-width: 800px;
        margin: 0 auto;
        padding: 20px;
        line-height: 1.6;

        h1,
        h2,
        h3,
        p {
            margin-bottom: 10px;
        }

        .policy-title {
            font-weight: bold;
            font-size: 2em;
            text-align: center;
            margin-bottom: 20px;
        }

        .last-updated {
            font-weight: normal;
            font-style: italic;
            text-align: center;
            font-size: 1em;
            margin-bottom: 20px;
        }

        .section-title {
            font-weight: 600;
            font-size: 1.5em;
            margin-top: 20px;
        }

        .subsection-title {
            font-weight: 500;
            font-size: 1.2em;
            margin-top: 15px;
        }

        .section {
            background-color: rgba(255, 255, 255, 0.1);
            padding: 10px;
            border-radius: 5px;
            margin-top: 10px;

            p {
                font-weight: normal;
                font-size: 1em;
                margin: 0;
            }
        }
    }
}

// For backward compatibility
.privacy-policy-wrapper {
    @extend .policy-wrapper;
    
    .privacy-policy {
        @extend .policy-content;
        
        .privacy-policy-title {
            @extend .policy-title;
        }
    }
}
