@import "Colors.scss";
@import "Fonts.scss";


div.root {
  width: 100%;
  
}

.layout {
  width: 100%;
}

body::-webkit-scrollbar {
  display: none;
}


html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior-y: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  background-color: $grid-darkBlue;
  background-size: cover;

}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.bold {
  font-weight: bolder;
  font-size: 1;
}

.navbar {
  padding: 0 !important;
}

.section {
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 100px;
  padding-top: 75px;
  position: static;
  background-color: #431f64;
  /* height: calc(100% - 100px); */
  height: auto;
}

hr.info-panel-divider-bottom, hr.info-panel-divider-mobile {
  opacity: 0%;
  height: 2px;
  margin: 0px;
}

// .counter-wrapper {
//   text-align: center;
//   padding: 40px 20px 20px 20px;
//   border-radius: 20px;
//   margin-left: auto;
//   margin-right: auto;
//   bottom: 20px;
// }



Label {
  color: white;
}

@media screen and (max-width: 767px) {
  .section {
    padding-top: 4vh;
  }
  // .counter-wrapper {
  //   padding-top: 5px;
  // }
  .info-panel-main-text {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  .info-panel {
    padding-bottom: 0 !important;
  }
}
.blinking1,
.blinking2,
.blinking3 {
  font-size: 50px;
}

.blinking1 {
  animation: opacity 2s ease-in-out infinite;
  opacity: 1;
}
.blinking2 {
  animation: opacity 2s ease-in-out infinite;
  opacity: 1;
  animation-delay: 250ms;
}
.blinking3 {
  animation: opacity 2s ease-in-out infinite;
  opacity: 1;
  animation-delay: 500ms;
}

@keyframes opacity {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

hr.info-panel-divider {
  margin: 1rem 0 1rem 0;
  opacity: 10%;
  height: 2px;
}

hr.info-panel-divider-bottom {
  opacity: 0%;
  height: 0px;
}

@media (max-width: 767px) {
  hr.info-panel-divider-mobile {
    opacity: 10%;
    height: 2px;
    color: rgb(223, 222, 222);
    margin: 1rem 0 2.5rem 0;
  }
  hr.info-panel-divider-bottom {
    margin: 1rem 0 2.5rem 0;
    opacity: 10%;
    height: 2px;
    color: rgb(223, 222, 222);
  }
  hr.info-panel-divider-bottom.no-margin {
    margin-bottom: 1rem !important;
  }
}

.social-wrapper {
  width: fit-content;
  /* float: right; */
  height: 80%;
  white-space: nowrap;
}

.socialIcon {
  margin-left: 5px;
}

.no-select {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
}